<template>
  <div>
    <TransferByLocation
      v-if="type === 1"
      f-type="warehouse"
      @onTypeChange="onTypeChange"
      @showQuantityConfig="showQuantityConfig"
    />
    <TransferByRate v-else-if="type === 2" />

    <v-dialog v-model="dialogQuantityConfig" max-width="1366px">
      <DialogQuantityConfig
        v-if="dialogQuantityConfig"
        @cancel="hideQuantityConfig"
      />
    </v-dialog>
  </div>
</template>

<script>
import { TRANSFER_TYPES } from "@/libs/const";
export default {
  components: {
    TransferByLocation: () =>
      import("@/components/pos_goods/TransferByLocation"),
    TransferByRate: () => import("@/components/pos_goods/TransferByRate"),
    DialogQuantityConfig: () =>
      import("@/components/pos_goods/DialogQuantityConfig"),
  },
  data: () => ({
    type: 1,
    typeOptions: [...TRANSFER_TYPES],
    dialogQuantityConfig: false,
  }),
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    showQuantityConfig() {
      this.dialogQuantityConfig = true;
    },
    hideQuantityConfig() {
      this.dialogQuantityConfig = false;
    },
    onTypeChange(type) {
      this.type = type;
    },
  },
};
</script>

<style scoped></style>
